.resp-time-container {
    display: flex;
    flex:1;
    flex-direction: column;
    text-align: center;
    line-height: 0%;
    border-radius: 8px;
    background: #FFF3F1;
  
}


 

.resp-time-container h1{
    color: #EF7E6C;
    font-family: Inter;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    
}

.resp-time-container h3{
    color: var(--white-natural-1, #00000F);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.resp-time-container h4{
    color: var(--white-natural-1, #00000F);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}



