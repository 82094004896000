.resp-delay-container {
    display: flex;
    flex:1;

    flex-direction: column;
    text-align: center;
    line-height: 0%;
    border-radius: 8px;
    background: #E8F7F4;
}



.resp-delay-container h1{
    color: #47A5A6;
font-family: Inter;
font-size: 44px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0px;
}

.resp-delay-container h3{
    color: var(--white-natural-1, #00000F);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.resp-delay-container h4{
    color: var(--white-natural-1, #00000F);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}





