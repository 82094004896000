.month-container {
    display: flex;
    flex:1;
    flex-direction: column;
    box-shadow: 1px 1px 15px  rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 170px !important; 
    height: 45% !important; 
    border-radius: 15px; 
    margin-bottom: 10px; 
    margin-top: 10px;
}

.month-container h4{
    margin-top: 1px;
    margin-bottom: 7px;
    font-size: 18px;
}

.month-picker {
    max-height: 100px;
    overflow: scroll;
    font-size: 16px;
}

input[type='checkbox'] {
    margin-bottom: 5px;
}




