

  
  
  
  
  .row-one{
    display: flex;
   
 
 
  }
  
  
  .row-two{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 5px;
    margin-right: 0px;
  
    
  }
  
  
  .row-allocation{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    margin-top: 10px;
   
  }
  
  
  
  .row-three{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;

   
  }