.date-container {
    display: flex;

    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  
  
   
    border-radius: 15px; 
    font-size: 15px;
}





  input[type="date"] {
    border: 1px solid rgb(214, 205, 205);
    border-radius: 10px; 
    padding: 5px 10px;
    font-size: 15px;
   
  }
  
 
 
  
