.status-container {
    display: flex;
    flex :1;
    flex-direction: column;
    text-align: center; 
    line-height: 0%; 
    border-radius: 8px;
    border: 1px solid #E4E3E3;
    background: #FBFBFB;
  
}

.status-container h4 {
    margin-top: 15px;
    margin-bottom: 13px;
    font-weight: 600;
}

.pie-container {
    display: flex;
    justify-content: center;
    margin-top: 1px;
}


.status-pop{
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 8px;
    font-size: 13px;
}

.status-pop p{
    margin: 17px;
}

.status-legend{
   display: flex;
   justify-content: center;
   flex-direction: column;
   font-size: 15px;
   margin-bottom: 25px;
}


.status-color{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}











