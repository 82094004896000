.product-container {
    display: flex;
    

    flex-direction: column;

    border-radius: 15px; 
}


.product-container h4{
    margin-top: 1px;
    font-size: 16px;
}


.product-select {
    font-size: 16px;
    margin-bottom: 3px;
    max-height: 110px;
    overflow: scroll;
}