.prio-container {
    display: flex;
  

    flex-direction: column;
    text-align: center;
    line-height: 0%; 
   
    
  
    
     
}


.prio-container h4{
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: start;
}


.priority {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.priority-box {
    /* width: 45px; */
    height:  45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
    box-shadow: 1px 1px 5px  rgba(0, 0, 0, 0.1);
}







