.bubble-container {
    display: flex;
    flex-direction: column;
    text-align: center; 
    box-shadow: 1px 1px 15px  rgba(0, 0, 0, 0.3);
    padding: 10px;
   
    
    border-radius: 15px; 
    margin-top: 0px; 
   
}


.pop-container {
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 15px;
}


.pop-container h4{
    text-align: center;
    margin-top: 1px;
    margin-bottom: 3px;
}

.table-head {
    background-color: black;
    color: #f5f5f5;
}

.color-key{
    font-size: 15px;    
}


.color-key-container {
    display: flex;
    justify-content: center;
    font-size: 15px;
  }
  

  .color-key-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    
  }
  
  .color-key-box {
    width: 7px;
    height: 7px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 7px;
  }


  


 





