.priority-container {
    display: flex;
    flex:1;

    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center; 
    justify-content: center;
    line-height: 0%; 
    box-shadow: 1px 1px 15px  rgba(0, 0, 0, 0.3);
   
    border-radius: 15px; 
    margin-bottom: 10px; 
    
}


.priority-container h4{
    margin-top: 15px;
    margin-bottom: 13px;
    font-weight: 600;
}


.priority-pie{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.priority-legend {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    margin-bottom: 25px;
   
  }









